@font-face {
  font-family: "brownbold";
  src: url("/assets/fonts/Brown-Bold.woff2") format("woff2"),
  url("/assets/fonts/Brown-Bold.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "brownlight";
  src: url("/assets/fonts/Brown-Light.woff2") format("woff2"),
  url("/assets/fonts/Brown-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "brownmedium";
  src: url("/assets/fonts/Brown-Medium.woff2") format("woff2"),
  url("/assets/fonts/Brown-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "brownregular";
  src: url("/assets/fonts/Brown-Regular.woff2") format("woff2"),
  url("/assets/fonts/Brown-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
