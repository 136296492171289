:root {
  --toastify-toast-width: 369px;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast {
  padding: 24px 24px 24px 20px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-icon {
  margin-right: 20px;
}

@keyframes slideInUp {
  from {
    transform: translateY(110%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(0);
    visibility: visible;
  }
  to {
    transform: translateY(-10%);
  }
}

.slide-in-toast {
  animation: slideInUp 300ms ease-in-out;
}

.slide-out-toast {
  animation: slideInDown 300ms ease-in-out;
}
