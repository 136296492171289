@import "@styles/core/index.scss";

.overlay_checkout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transform: translate(100%, 0%);
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  transition: opacity .5s ease-in-out;
}

.checkout_dialog {
  position: fixed;
  width: 435px;
  background: $body;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: right .4s ease-in-out;
  :global {
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid #938985;
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: "brownregular", serif;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $primary;
        height: 81px;

      }
      .close_button {
        position: absolute;
        right: 0;
        height: 100%;
        aspect-ratio: 1/1;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          cursor: pointer;
          width: 11.67px;
          height: 11.67px;

        }
      }
    }
    .cart_list_items {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 26px 0;
      row-gap: 26px;
      overflow-y: scroll;
      .item {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
        width: calc(100% - 40px);
        img {
          width: 82.9px;
          height: 82.9px;
        }
        .center {
          justify-content: center !important;
        }
        .detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 22.57px;
          span {
            text-transform: uppercase;
            font-family: "brownregular", serif;
            font-size: 16px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: $primary;
          }
          p {
            margin-top: 8px;
            font-size: 12px;
            line-height: 11px;
            letter-spacing: 0.01em;
            color: $primary;
          }
          .quantity_manage {
            flex: 1;
            position: relative;
            .quantity_adjust {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 135px;
              height: 31px;
              background: $text-color-white;
              border: 1px solid #E1DDDA;
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              align-items: center;
              button {
                flex: 1 0;
                border: none;
                background-color: transparent;
                font-size: 24px;
                line-height: 24px;
                cursor: pointer;
                color: $primary;
                height: 100%;
                margin-bottom: 8px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  opacity: 0.8;
                }
              }
              .button_minus {
                margin-bottom: 7px;
              }
              p {
                flex: 1.5;
                margin-top: 0;
                font-family: "brownregular", serif;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                letter-spacing: 0.05em;
                color: $primary;
              }
            }
          }
        }
        .delete_price {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          img {
            cursor: pointer;
            margin-top: 7px;
            width: 12px;
            height: 16px;
          }
          span {
            margin-bottom: 13px;
            font-size: 16px;
            line-height: 15px;
            text-align: right;
            letter-spacing: 0.01em;
            color: $primary;
          }
        }
      }
    }
    hr {
      height: 1px;
      background-color: $primary;
      border: none;
    }
    .discount_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 22px 20px 0;
      width: calc(100% - 40px);
      .input_box {
        position: relative;
        flex: 1;
        height: 50px;
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #938985;
          font-family: "brownregular", serif;
          font-size: 16px;
          line-height: 15px;
          color: #938985;
          background: transparent;
          padding: 0 12px;
          letter-spacing: 0.01em;
          &:focus {
            outline: none;
            border-color: $title-secondary;
          }
          &:focus::placeholder {
            color: transparent;
          }
        }
        img {
          position: absolute;
          top: 19px;
          right: 10px;
          cursor: pointer;
        }
      }
      .discount_apply {
        width: 108px;
        margin-left: 25px;
      }
    }
    .sub_total_container {
      display: flex;
      justify-content: space-between;
      margin: 23px 20px 0;
      width: calc(100% - 40px);
      span {
        font-family: "brownregular", serif;
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: $primary;
      }
    }
    .checkout {
      margin: 22px 20px 20px;
      width: calc(100% - 40px);
      img {
        margin-right: 9px;
      }
    }
  }
}

.overlay_checkout_show {
  opacity: 1;
  transform: translate(0%, 0%);
}

.checkout_dialog_show {
  right: 0;
}

@media screen and (max-width: 480px) {
  .checkout_dialog {
    width: 100%;
  }
}
