@import "@styles/core/index.scss";

.button {
  border: none;
  width: 240px;
  height: 50px;
  text-transform: uppercase;
  font-family: "brownregular", serif;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: $text-color-white;
  background: #938985;
  transition: all 0.4s ease-in-out;
  &:hover {
    opacity: 80%;
  }
}
