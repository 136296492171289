@import "../../core/index";

.footer {
  width: 89.584%;
  margin: 0 5.208%;
  background-color: $primary;
  :global {
    .info_subscribe_container {
      padding-top: 64px;
      display: flex;
      height: 271px;
      .footer_detail {
        flex: 3 1 auto;
        padding-left: 48px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 17px;
        line-height: 16px;
        letter-spacing: 0.05em;
        img {
          height: 28px;
        }
        .welcome {
          font-family: "brownregular", serif;
          text-transform: uppercase;
          margin-top: 51.6px;
          color: rgba(249, 247, 242, 0.75);
        }
        .detail {
          font-family: "brownregular", serif;
          text-transform: uppercase;
          margin-top: 16px;
          color: $text-color-white;
        }
      }
      .subscribe {
        padding-right: 48px;
        .special-offer-text {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 15px;
          color: $text-color-white;
          text-transform: uppercase;
          letter-spacing: 0.05rem;
        }
        form {
          &.hide {
            display: none;
          }
          margin-top: 16px;
          .form-container {
            display: flex;
            height: 50px;
            input {
              flex: 1.5 1 auto;
              background: transparent;
              border: 1px solid rgba(249, 247, 242, 0.5);
              padding: 13px 25px;
              color: white;
              font-family: "brownregular", serif;
              font-size: 16px;
              line-height: 15px;
              width: 285px;
              &:focus {
                outline: none;
                border-color: $text-color-white;
              }
              &:focus::placeholder {
                color: transparent;
              }
            }
            button {
              flex: 1 1 auto;
              width: 164px;
              cursor: pointer;
              background: transparent;
              color: #f9f7f2;
              border: 1px solid rgba(249, 247, 242, 0.5);
              justify-content: center;
              align-items: center;
              display: flex;
              text-transform: uppercase;
              font-size: 15px;
              line-height: 15px;
              text-align: center;
              letter-spacing: 0.05em;
              font-family: "brownregular", serif;
              transition: all 300ms linear;
              &.hide {
                display: none;
              }
              &:hover {
                background: white;
                color: $primary;
              }
            }
            .loading {
              flex: 1 1 auto;
              width: 164px;
              cursor: pointer;
              background: transparent;
              color: #f9f7f2;
              border: 1px solid rgba(249, 247, 242, 0.5);
              justify-content: center;
              align-items: center;
              display: none;
              &.show {
                display: flex;
              }
              .dot-falling {
                position: relative;
                left: -10008px;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $text-color-white;
                color: $text-color-white;
                animation: dot-falling 1s infinite linear;
                animation-delay: 0.1s;
              }
              .dot-falling::before, .dot-falling::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
              }
              .dot-falling::before {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $text-color-white;
                color: $text-color-white;
                animation: dot-falling-before 1s infinite linear;
                animation-delay: 0s;
              }
              .dot-falling::after {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $text-color-white;
                color: $text-color-white;
                animation: dot-falling-after 1s infinite linear;
                animation-delay: 0.2s;
              }
            }
          }
          .error-message {
            display: none;
            padding-top: 12px;
            color: $red;
            &.show {
              display: block;
            }
          }
          .agree-checkbox {
            color: rgba(249, 247, 242, 0.75);
            display: inline-flex;
            margin-top: 16px;
            height: auto;
            input {
              height: 18px;
              width: 18px;
              background: transparent;
              border-radius: 0.293122px;
              border: 1px solid rgba(249, 247, 242, 0.5);
              margin-right: 15px;
              -webkit-appearance: none;
              appearance: none;
              outline: none;
              transition-duration: 0.3s;
              cursor: pointer;
              margin-top: 6px;
              position: relative;
              &:checked {
                border: 1px solid #fff !important;
                background-color: #344e;
                &::before {
                  content: "\2713";
                  color: white;
                  position: absolute;
                  left: 2.95px;
                  bottom: 0;
                }
              }
            }
            .checkbox-error {
              border: 1px solid $red;
            }
            label {
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.06em;
              color: rgba(249, 247, 242, 0.75);
              cursor: pointer;
            }
          }
        }
        .subscribed-messages {
          display: none;
          width: 449px;
          margin-top: 30px;
          font-family: "brownregular", serif;
          font-size: 16px;
          line-height: 15px;
          letter-spacing: 0.05em;
          color: $golden;
          &.show {
            display: block;
          }
        }
      }
    }
    .other_links {
      display: flex;
      margin: 0 48px 0;
      border-bottom: 2px solid rgba(249, 247, 242, 0.1);
      text-transform: uppercase;
      cursor: pointer;
      span, a {
        text-decoration: none;
        font-family: "brownregular", serif;
        padding: 24px 40px 24px 0;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: $text-color-white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .nav_links {
      margin: 0 48px;
      padding: 24px 0;
      color: rgba(249, 247, 242, 0.5);
      ul {
        list-style-type: none;
        display: flex;
        gap: 9px;
        font-family: "brownregular", serif;
        line-height: 11px;
        font-size: 9px;
        align-items: center;
        li {
          font-family: "brownregular", serif;
          line-height: 11px;
          font-size: 9px;
          &:last-child {
            padding-top: 1px;
          }
          a {
            font-family: inherit;
            line-height: inherit;
            font-size: inherit;
            text-decoration: none;
            color: rgba(249, 247, 242, 0.5);
            &:hover {
              color: $text-color-white;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    width: 100%;
    margin: 0;
    :global {
      .info_subscribe_container {
        flex-direction: column;
        height: auto;
        padding-top: 42px;
        .footer_detail {
          font-size: 12px;
          padding-left: 24px;
          img {
            width: 89px;
            height: 17px;
          }
          .welcome {
            margin-top: 25px;
          }
          .detail {
            margin-top: 8px;
          }
        }
        .subscribe {
          padding-top: 35px;
          padding-left: 24px;
          padding-right: 0;
          .special-offer-text {
            font-size: 9.35px;
          }
          form {
            margin-top: 10px;
            .form-container {
              height: 42px;
              width: 85%;
              input {
                font-size: 12px;
                width: 171px;
                padding: 16px 10px;
                border-radius: 0;
              }
              button {
                flex: auto;
                width: 99px;
                font-size: 11px;
              }
              .loading {
                width: 99px;
                .dot-falling {
                  left: -9999px;
                }
                .dot-falling, .dot-falling::before, .dot-falling::after {
                  width: 5px;
                  height: 5px;
                }
              }
            }
            .error-message {
              font-size: 10px;
            }
            .agree-checkbox {
              margin-top: 20px;
              input {
                height: 16px;
                width: 16px;
                margin-right: 7px;
                margin-top: 2px;
                &:checked {
                  &::before {
                    font-size: 7px;
                    top: 1.5px;
                    left: 2.7px;
                  }
                }
              }
              label {
                font-size: 12px;
                line-height: 1.5;
              }
            }
          }
          .subscribed-messages {
            font-size: 12px;
            margin-top: 6px;
            width: auto;
            height: 71.11px;
          }
        }
      }
      .other_links {
        margin: 40px 24px 0;
        span, a {
          font-size: 12px;
          padding: 10px 20px 10px 0;
        }
      }
      .nav_links {
        padding: 9px 0;
        margin: 0 24px;
        ul {
          display: flex;
          flex-flow: wrap;
          row-gap: 10px;
          font-size: 12px !important;
          li {
            font-size: 12px !important;
            a {
              display: inline-flex;
              align-items: center;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 911px) {
  .footer {
    width: 100%;
    margin: 0;
    :global {
      .info_subscribe_container {
        .footer_detail {
          font-size: 12px;
          .detail {
            margin-top: 8px;
          }
        }
        .subscribe {
          form {
            .form-container {
              input {
                width: 185px;
              }
              button {
                width: 100px;
                font-size: 12px;
              }
              .loading {
                width: 100px;
              }
            }
          }
          .subscribed-messages {
            width: 350.61px;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 912px) and (max-device-width: 1024px) and (orientation: portrait) {
  .footer {
    width: 100%;
    margin: 0;
    :global {
      .info_subscribe_container {
        .footer_detail {
          font-size: 12px;
          .detail {
            margin-top: 8px;
          }
        }
      }
    }
  }
}
