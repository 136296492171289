@import "../../core/index";

.header {
  width: 89.584%;
  margin: 0 5.208%;
  height: 42px;
  position: fixed;
  top: 0;
  z-index: 1000;
  :global {
    .announcement {
      background-color: $primary;
      color: rgba(249, 247, 242, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      padding: 0 48px;
      .swiper {
        .swiper-button-prev, .swiper-button-next {
          background-image: none;
          display: flex;
          align-items: center;
          img {
            width: 7px;
            height: 12px;
          }
        }
        .swiper-button-prev {
          justify-content: flex-start;
          left: 0;
        }
        .swiper-button-next {
          justify-content: flex-end;
          right: 0;
        }
        .swiper-pagination {
          display: none;
        }
        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      div {
        font-family: "brownlight", serif;
        line-height: 15px;
        font-size: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-decoration: none;
        color: $text-color-white;
        strong {
          font-family: "brownregular", serif;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    height: 42px;
    width: 100%;
    margin: 0;
    :global {
      .announcement {
        padding: 0 0;
        .swiper {
          .swiper-button-prev, .swiper-button-next {
            justify-content: center;
          }
        }
        div {
          font-size: 8px;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header {
    width: 100%;
    margin: 0;
    :global {
      .announcement {
        padding: 0 0;
        div {
          font-size: 9px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    width: 100%;
    margin: 0;
    :global {
      .announcement {
        padding: 0 20px;
        div {
          font-size: 11px;
        }
      }
    }
  }
}
