@import "variables";

@mixin dropdown_item_list {
  .custom_box {
    text-transform: uppercase;
    font-family: 'brownregular', serif;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: $primary;
    display: block;
    a {
      text-transform: uppercase;
      font-family: 'brownregular', serif;
      color: $primary;
      text-decoration: none;
      transition: all 0.3ms ease 0s;
      &:hover {
        color: #CD5F5F;
      }
    }
  }
  ul {
    display: grid;
    margin-top: 14px;
    list-style: none;
    li {
      margin: 0 0;
      padding-top: 16px;
      a {
        text-transform: uppercase;
        font-family: "brownlight", serif;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-decoration: none;
        transition: all 0.3ms ease 0s;
        color: $title-secondary;
        &:hover {
          color: #CD5F5F;
        }
      }
    }
  }
}

@mixin header_width {
  width: 89.584%;
  margin: 0 5.208%;
  background: $secondary;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
}

@mixin app_width {
  width: 94.792%;
  margin: 0 2.604%;
  background: $secondary;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
}

@mixin light_font {
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: $primary;
  @media screen and (max-width: 480px){
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin title_regular {
  font-family: "brownregular", serif;
  text-align: justify;
  letter-spacing: 0.01em;
  color: $primary;
}
