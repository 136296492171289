@import "../../core/index";

.header {
  width: 89.584%;
  margin: 0 5.208%;
  height: 104px;
  position: fixed;
  top: 0;
  z-index: 1000;
  animation-duration: 2s;
  animation-name: slidein-low;
  :global {
    .container {
      height: 100%;
      background-color: #F9F7F2;
      padding: 0 0 0 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        cursor: pointer;
        text-align: center;
        position: absolute;
        left: calc(50% - 78.71px);
        img {
          width: 157.42px;
          height: 28.83px;
        }
      }
      nav {
        height: inherit;
        .nav__links {
          list-style: none;
          height: inherit;
          z-index: 999;
          display: flex;
          transition: all 0.4s ease-in-out;
          li {
            height: inherit;
            &:not(:last-child,:first-child) {
              margin: 0 40px;
            }
            a {
              display: flex;
              align-items: center;
              height: inherit;
              text-transform: uppercase;
              font-family: 'brownregular', serif;
              font-size: 14px;
              line-height: 15px;
              letter-spacing: 0.05em;
              text-decoration: none;
              transition: all 0.3ms ease 0s;
              cursor: pointer;
              color: $primary;
              &:hover {
                color: #CD5F5F;
              }
            }
            .drop_down_item {
              display: inline-flex;
              align-items: center;
              height: inherit;
              text-transform: uppercase;
              font-family: 'brownregular', serif;
              font-size: 14px;
              line-height: 15px;
              letter-spacing: 0.05em;
              transition: all 0.3ms ease 0s;
              cursor: pointer;
              color: $primary;
              &:hover {
                color: #CD5F5F;
              }
            }
            &:hover .dropdown_list {
              display: block;
            }
            &:hover .drop_down_product {
              display: block;
            }
            .drop_down_product {
              display: none;
              width: 100%;
              margin: 0;
              position: absolute;
              border-top: 1px solid #D2D2D3BF;
              left: 0;
              right: 0;
              top: 104px;
              z-index: 1;
              background-color: #F9F7F2;
              padding: 55px 114px 55px 336px;
              .section_items {
                position: relative;
                display: flex;
                .featured {
                  flex: 2.5 1 auto;
                  position: relative;
                  overflow: hidden;
                  padding-right: 54px;
                  div {
                    float: right;
                    @include dropdown_item_list;
                  }
                }
                .list_sections {
                  flex: 1 1 auto;
                  div {
                    @include dropdown_item_list;
                  }
                  .herb_mix {
                    margin-top: 63px;
                  }
                }
              }
              img {
                position: absolute;
                left: 114px;
                bottom: 35px;
                width: 318px;
                height: 189px;
                object-fit: contain;
              }
            }
            .dropdown_list {
              display: none;
              width: 229.6px;
              border-top: 1px solid #D2D2D3BF;
              position: absolute;
              margin-left: -32px;
              top: 104px;
              z-index: 1;
              background-color: #F9F7F2;
              div {
                margin: 46px 32px;
                .custom_box {
                  a {
                    text-transform: uppercase;
                    font-family: 'brownregular', serif;
                    color: $primary;
                    text-decoration: none;
                    transition: all 0.3ms ease 0s;
                    &:hover {
                      color: #CD5F5F;
                    }
                  }
                }
                ul {
                  margin-top: 14px;
                  list-style: none;
                  li {
                    padding-top: 16px;
                    width: 100%;
                    margin: 0;
                    a {
                      text-transform: uppercase;
                      font-family: "brownlight", serif;
                      font-size: 14px;
                      line-height: 15px;
                      letter-spacing: 0.05em;
                      text-decoration: none;
                      transition: all 0.3ms ease 0s;
                      color: $title-secondary;
                      &:hover {
                        color: #CD5F5F;
                      }
                    }
                  }
                }
              }
            }
            .about {
              div {
                ul {
                  li {
                    &:first-child {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .nav_right {
        display: flex;
        height: inherit;
        align-items: center;
        .divider {
          margin-left: 34.8px;
          width: 2px;
          height: 80%;
          background-color: #D2D2D3BF;
          transition: all 0.4s ease-in-out;
        }
        .back_arrow {
          display: none;
          img {
            height: 20px;
            width: 20px;
            transform: rotate(90deg);
          }
        }
        .search_box_cart {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 35px 40px 35px 35px;
          height: 80%;
          transition: all 0.4s ease-in-out;
          &:hover img {
            opacity: 0.7;
          }
          img {
            height: 22px;
            width: 22px;
            transition: all 0.4s ease-in-out;
          }
          .search-box {
            .search_input {
              border: 1px solid #938985;
              width: 0;
              height: 42px;
              visibility: hidden;
              background: transparent;
              color: #938985;
              font-size: 15px;
              line-height: 17px;
              letter-spacing: 0.05em;
              transition: all 0.4s ease-in-out;
              &::placeholder {
                text-transform: uppercase;
              }
              &:focus {
                outline: none;
              }
            }
            .show {
              padding: 0 12px;
              margin-right: 14px;
              visibility: visible;
              width: 287px;
            }
          }
          .menu_icon {
            display: none;
            background: transparent;
            cursor: pointer;
            .bar1, .bar2, .bar3 {
              width: 22px;
              height: 2px;
              background-color: $primary;
              margin: 5px 0;
              transition: 0.4s;
            }
          }
          .menu_icon_cross .bar1 {
            transform: translate(0, 7px) rotate(-45deg);
          }
          .menu_icon_cross .bar2 {
            opacity: 0;
          }
          .menu_icon_cross .bar3 {
            transform: translate(0, -7px) rotate(45deg);
          }
        }
        .cart {
          padding: 29.25px 40px 29.25px 35px;
          position: relative;
          img {
            height: 27px;
            width: 21px;
          }
          .item_count {
            width: 21px;
            aspect-ratio: 1/1;
            position: absolute;
            bottom: 19px;
            right: 29.5px;
            border-radius: 50%;
            background: $secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              padding: 3px 5px;
              font-size: 12px;
              line-height: 11px;
              letter-spacing: 0.01em;
              color: $primary;
            }
          }
        }
      }
    }
  }
}
.header_announcement{
  top: 42px;
}
.side_menu {
  display: none;
  background-color: #F9F7F2;
  transform: translate3d(100%, 0, 0);
  top: 69px;
  bottom: 0;
  padding-top: 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  border-top: 0.5px solid rgba(210, 210, 211, 0.7490196078);
  :global {
    .container {
      width: 100%;
      height: 100%;
      position: relative;
      .search {
        height: 60px;
        display: flex;
        border-bottom: 1px solid rgba(210, 210, 211, 0.7490196078);
        input {
          padding-left: 30px;
          background: transparent;
          outline: none;
          border: none;
          text-transform: uppercase;
          height: 100%;
          width: 100%;
          font-size: 16px;
          line-height: 15px;
          color: $primary;
          -webkit-appearance: none;
        }
        button {
          margin-right: 30px;
          padding: 0;
          border: none;
          background: none;
          img {
            height: 18px;
            width: 18px;

          }
        }
      }
      ul {
        padding: 30px 30px;
        display: flex;
        flex-direction: column;
        list-style: none;
        gap: 18px;
        li {
          div {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            a {
              text-decoration: none;
              font-family: "brownregular", serif;
              color: #575759;
              &:visited {
                color: #575759;
              }
            }
            span {
              flex: 1;
              font-family: "brownregular", serif;
              color: #575759;
            }
            img {
              height: 15px;
              width: 15px;
              transform: rotate(270deg);
            }
            .inner_list {
              position: absolute;
              top: 0;
              left: 100%;
              width: 100%;
              height: 100%;
              padding: 30px 30px;
              background-color: #F9F7F2;
              visibility: hidden;
              transition: visibility 0s linear 1s;
              z-index: 888;
              flex-direction: column;
              overflow-y: auto;
              ul {
                gap: 30px;
                width: 100%;
                padding: 0;
                li {
                  span {
                    font-family: "brownregular", serif;
                    font-size: 16px;
                    color: #575759;
                  }
                  ul {
                    margin-top: 20px;
                    gap: 15px;
                    li {
                      a {
                        display: flex;
                        width: 100%;
                        span {
                          flex: 1;
                          font-family: "brownlight", serif;
                          font-size: 14px;
                          color: $primary;
                          text-transform: uppercase;
                        }
                        img {
                          height: 15px;
                          width: 15px;
                          transform: rotate(270deg);
                        }
                      }
                    }
                  }
                }
              }
            }
            .inner_list_active {
              left: 0;
              visibility: visible;
              transition: visibility 0s linear 0s;
            }
          }
        }
      }
    }
  }
}

.show {
  transform: translateZ(0);
}

@media screen and (max-width: 480px) {
  .header {
    height: 69px;
    width: 100%;
    margin: 0;
    :global {
      .container {
        width: 100%;
        margin: 0;
        padding: 0;
        .logo {
          img {
            margin-top: 5px;
            height: 17px;
          }
        }
        nav {
          display: none;
        }
        .nav_right {
          margin-left: auto;
          .nav__links {
            display: none;
          }
          .back_arrow {
            padding-left: 15px;
            flex: 1;
          }
          .back_arrow_show {
            display: block;
            z-index: 1000;
          }
          .divider {
            padding-left: 0;
            height: 60%;
          }
          .search_box_cart {
            padding: 25px 25px;
            &:hover {
              transition: all 0.4s ease-in-out;
              background: transparent;
            }
            img {
              display: none;
            }

            .menu_icon {
              display: inline-block;
              z-index: 1000;
            }
          }
          .cart {
            .item_cart {
              margin-left: 20px;
              display: block;
            }
            .item_count {
              bottom: 7px;
              right: 14.5px;
            }
          }
        }
        .nav_right_menu_open {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
  .side_menu {
    display: block;
  }
  .side_menu_announcement{
    top: 111px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header {
    width: 100%;
    margin: 0;
    :global {
      .container {
        width: 100%;
        margin: 0;
        nav {
          display: none;
        }
        .nav_right {
          margin-left: auto;
          .nav__links {
            display: none;
          }
          .back_arrow {
            padding-left: 0;
            flex: 1;
          }
          .back_arrow_show {
            display: block;
          }
          .search_box_cart {
            &:hover {
              transition: all 0.4s ease-in-out;
              background: transparent;
            }
            img {
              display: none;
            }
            .item_cart {
              margin-left: 20px;
              display: block;
            }
            .menu_icon {
              display: inline-block;
            }
          }
        }
        .nav_right_menu_open {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
  .side_menu {
    display: block;
    top: 104px;
  }
  .side_menu_announcement{
    top: 146px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    width: 100%;
    margin: 0;
    :global {
      .container {
        width: 100%;
        margin: 0;
        nav {
          display: none;
        }
        .nav_right {
          margin-left: auto;
          .nav__links {
            display: none;
          }
          .back_arrow {
            padding-left: 0;
            flex: 1;
          }
          .back_arrow_show {
            display: block;
            z-index: 1000;
          }
          .search_box_cart {
            &:hover img {
              transition: all 0.4s ease-in-out;
              background: transparent;
            }
            img {
              display: none;
            }
            .item_cart {
              margin-left: 20px;
              display: block;
            }
            .menu_icon {
              z-index: 1000;
              display: inline-block;
            }
          }
        }
        .nav_right_menu_open {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
  .side_menu {
    display: block;
    top: 104px;
  }
  .side_menu_announcement{
    top: 146px;
  }
}

@keyframes slidein-low {
  from {
    top: -150px;
  }
  to {
    top: 0;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1190px) {
  .header {
    :global {
      .container {
        nav {
          .nav__links {
            li:not(:last-child, :first-child) {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1190px) {
  .header {
    :global {
      .container {
        .nav_left {
          .nav__links {
            li {
              .drop_down_product {
                padding: 55px 48px 55px 243px;
                img {
                  left: 69px;
                  bottom: 52px;
                  width: 240px;
                }
              }
            }
          }
        }
      }
    }
  }
}
