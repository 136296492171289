@import "core/index";
@import "pages/inedx/index";
@import "themes/themes";
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';
@import 'react-toastify/dist/ReactToastify.css';
@import "vendors/react-tostify";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "brownlight", serif;
  font-weight: 300;
}

body {
  background-color: $body;
  margin: 0;
  padding: 0;
  font-family: "brownlight", serif;
  font-weight: 300;
  position: relative;
}

.hide-google-tag-no-script {
  display: none;
  visibility: hidden;
}

.main_container {
  margin: auto;
}

.hide-scroll {
  overflow: hidden;
}

button {
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.product-container {
  width: 89.584%;
  margin: 0 5.208%;
  text-align: center;
  .titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 47px;
    padding-bottom: 45px;
    overflow: hidden;
    .title-normal {
      font-family: "brownregular", serif;
      font-size: 18px;
      letter-spacing: 0.05em;
      line-height: 20px;
      text-transform: uppercase;
    }
    .title-bold {
      padding: 38px 0 41px 0;
      font-family: "brownbold", serif;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }
  .swiper {
    padding-bottom: 77px;
    .slide-up-animation-active {
      opacity: 1;
      transform: translateZ(0);
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    }
    .slide-up-animation {
      opacity: 0;
      transform: translate3d(0, 80px, 0);
    }
    .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 48px !important;
      height: 50px;
      width: 50px;
      padding: 19.67px 19.39px 17.78px 20.94px;
      background: rgba(225, 221, 218, 0.75);
      transition: all 0.4s ease-in-out;
      img {
        width: 12.55px !important;
        height: 12.55px !important;
      }
      &:hover {
        opacity: 1;
        filter: brightness(107%);
      }
    }
    .swiper-button-next {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 48px !important;
      height: 50px;
      width: 50px;
      padding: 19.67px 19.39px 17.78px 20.94px;
      background: rgba(225, 221, 218, 0.75);
      transition: all 0.4s ease-in-out;
      img {
        width: 12.55px !important;
        height: 12.55px !important;
      }
      &:hover {
        opacity: 1;
        filter: brightness(107%);
      }
    }
    .swiper-pagination {
      bottom: 40px !important;
      .swiper-pagination-bullet {
        background: #000;
        opacity: 0.2;
      }
      .swiper-pagination-bullet-active {
        background: #938985;
        opacity: 1;
      }
    }
  }
  .image_animation {
    animation: about-image-in 1s ease forwards;
  }
}

@media screen and (max-width: 480px) {
  .product-container {
    width: 100%;
    margin: 0;
    .titles {
      padding-top: 58px;
      .title-normal {
        p{
          font-size: 12px;
          line-height: 22px;
          br{
            display: none;
          }
        }
      }
      .title-bold {
        padding: 16px 0;
        font-size: 32px;
      }
    }
    .swiper {
      .swiper-button-prev {
        left: 24px !important;
      }
      .swiper-button-next {
        right: 24px !important;
      }
    }
  }
  .header-space {
    height: 42px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .product-container {
    width: 100%;
    margin: 0;
    .titles {
      padding-top: 40px;
      .title-normal {
        font-size: 12px;
      }
      .title-bold {
        padding: 16px 0;
        font-size: 32px;
        line-height: 45px;
      }
    }
    .swiper {
      .swiper-button-prev {
        left: 24px !important;
      }
      .swiper-button-next {
        right: 24px !important;
      }
      .swiper-pagination {
        display: none;
      }
    }
  }
  .header-space {
    height: 42px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .product-container {
    width: 100%;
    margin: 0;
  }
  .header-space {
    height: 42px;
  }
}
