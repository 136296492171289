@import "@styles/core/index";

.our-products {
  margin: 58px 0;
  text-align: center;
  p {
    font-size: 15px;
    color: $title-secondary;
    text-align: center;
    width: 55%;
    margin: 20px auto 0;
    line-height: 25px;
  }
  h1 {
    text-transform: uppercase;
    font-family: "brownmedium", serif;
    font-size: 36px;
    line-height: 53px;
    letter-spacing: 0.05em;
    color: $primary;
  }
}

@media screen and (max-width: 480px) {
  .our-products {
    margin: 40px 0;
    p {
      font-size: 12px;
      padding: 0;
      width: 85%;
      line-height: 22px;
    }
    h1 {
      font-size: 26px;
      margin-top: 8px;
      margin-bottom: 8px;
      line-height: normal;
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .our-products {
    margin: 40px 0;
    p {
      font-size: 10px;
      padding: 0;
      width: 80%;
    }
    h1 {
      font-size: 28px;
      margin-top: 8px;
      margin-bottom: 10px;
      line-height: normal;
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .our-products {
    h1 {
      font-size: 28px;
    }
    p{
      width: 77%;
    }
  }
}
