$primary: #1D1F22;
$secondary: #ECE8E1;
$title-secondary: #575759;
$body: #F4F3F0;
$text-color-white: #F9F7F2;
$red: #CD5F5F;
$golden: #C2AB7A;
$gray: #938985;
:export {
  primary: $primary;
  secondary: $secondary;
  title-secondary: $title-secondary;
  body: $body;
  text-color-white: $text-color-white;
  red: $red;
  golden: $golden;
  gray: $gray;
}
