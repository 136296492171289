@import "variables";

@keyframes about-image-in {
  0% {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes fade-in-honeycomb {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dot-falling {
  0% {
    box-shadow: 10008px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10008px 0 0 0 $text-color-white;
  }
  100% {
    box-shadow: 10008px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 $text-color-white;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-after {
  0% {
    box-shadow: 10033px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10033px 0 0 0 $text-color-white;
  }
  100% {
    box-shadow: 10033px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@media screen and (max-width: 480px) {

  @keyframes dot-falling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%, 50%, 75% {
      box-shadow: 9999px 0 0 0 $text-color-white;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }

  @keyframes dot-falling-after {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%, 50%, 75% {
      box-shadow: 10014px 0 0 0 $text-color-white;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
}
